import React from 'react'
import cx from 'classnames'

import BuyTry from '@/components/blocks/BuyTry'
import { Column, Section } from '@/components/layout'
import { Text, ButtonGroup } from '@/components/common'
import PricingCta from '@/components/PricingCta'
import TryFreeCta from '@/components/TryFreeCta'
import HomePageStats from '@/components/Pages/HomePage/HomePageStats'
import HomePageQuotes from '@/components/Pages/HomePage/HomePageQuotes'
import HomePageFeatures from '@/components/Pages/HomePage/HomePageFeatures'
import styles from '@/components/Pages/HomePage/HomePage.module.scss'

const pageId = 'home'
const getMessageId = (id) => `pages.${pageId}.${id}`

export default function HomePage() {
  return (
    <>
      <Section className={styles.headerSection} wrapperClassName={styles.headerWrapper}>
        <Column className="w-full">
          <div className={styles.heroHolder}>
            <div className="max-w-700">
              <Text.H1 brandPrimary messageId={getMessageId('main.header.title')} normal size="medium" />
              <Text.H2 messageId={getMessageId('main.header.subtitle')} size="xlarge" />
              <BuyTry className={styles.headerBuyTry} justifyStart noOutline testIdPrefix="homepage" />
            </div>
          </div>
        </Column>
      </Section>
      <HomePageFeatures />
      <HomePageStats />
      <HomePageQuotes />
      <Section className={styles.securitySection} wrapperClassName={styles.securityWrapper}>
        <div className={cx(styles.securityTextWrapper, 'max-w-600')}>
          <Text.H2 brandSecondary messageId={getMessageId('security.title')} normal />
          <Text.P messageId={getMessageId('security.p')} onTint />
        </div>
      </Section>
      <Section>
        <ButtonGroup>
          <PricingCta messageId="cta.seePlansAndPricing" size="xlarge" />
          <TryFreeCta />
        </ButtonGroup>
      </Section>
    </>
  )
}

HomePage.layoutProps = {
  pageId,
}
