import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import Image from 'next/image'

import { Columns, Column, Section, Rows } from '@/components/layout'
import { Text, Link, IconList } from '@/components/common'
import routes from '@/routes'
import fileCabinetNeatfilesImg from '@/assets/home/cabinet-neatfiles-updated.png'

import styles from './HomePage.module.scss'

const getMessageId = id => `pages.home.features.${id}`

const iconListItems = [
  'purple-reports',
  'purple-filter',
  'purple-mobilescan',
]

export default function HomePageFeatures({ replaceCtasWith }) {
  const { formatMessage } = useIntl()

  return (
    <Section wrapperClassName={styles.featuresWrapper}>
      <Rows>
        <Text.H2 className={styles.featuresTitle} messageId={getMessageId('title')} />
        <Columns>
          <Column className={styles.featuresLeftCol} size={40}>
            <Image
              alt={formatMessage({ id: getMessageId('cabinetAlt') })}
              src={fileCabinetNeatfilesImg}
              style={{ height: 'auto' }}
            />
          </Column>
          <Column className={styles.featuresRightCol} size={60}>
            <Text.H3 messageId={getMessageId('subtitle')} />
            <IconList
              className={styles.featuresIconList}
              items={iconListItems.map((image, ix) => ({
                src: `/home/${image}.png`,
                text: formatMessage({ id: getMessageId(`li${ix + 1}`) }),
                className: styles.featureListItem,
                id: image,
              }))}
            />
            { replaceCtasWith || <Link
              className="self-center"
              messageId={getMessageId('cta')}
              size="xlarge"
              to={routes.neatFeatures}
              variant="primary"
            /> }
          </Column>
        </Columns>
      </Rows>
    </Section>
  )
}

HomePageFeatures.propTypes = {
  replaceCtasWith: PropTypes.element,
}
