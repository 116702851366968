import React from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import Image from 'next/image'

import { Columns, Column, Section, Rows } from '@/components/layout'
import { Card, Text } from '@/components/common'
import spunkyLadyImg from '@/assets/home/spunky-lady.png'

import styles from './HomePage.module.scss'

const QuoteCard = ({ author, quote }) => (
  <Card className={styles.quoteCard} withShadow>
    <Rows center textCenter>
      <Text.P bold color="foreground-primary">{quote}</Text.P>
      <Text.P size="small"><FormattedMessage id="pages.home.quotes.authorPrefix" />{author}</Text.P>
    </Rows>
  </Card>
)

QuoteCard.propTypes = {
  author: PropTypes.string.isRequired,
  quote: PropTypes.string.isRequired,
}

export default function HomePageQuotes() {
  const { formatMessage } = useIntl()

  const getMessage = id => formatMessage({ id: `pages.home.quotes.${id}` })

  const renderQuoteColumns = (indexes) => (
    <Column className={styles.quoteColumn} gap={32}>
      {indexes.map(i => <QuoteCard key={i} author={getMessage(`author${i}`)} quote={getMessage(`quote${i}`)} />)}
    </Column>
  )

  return (
    <Section bgSecondary>
      <Columns alignCenter wrap>
        <Column className={styles.quoteImageColumn}>
          <Image
            alt="spunky-lady"
            src={spunkyLadyImg}
            style={{
              height: 'auto',
            }}
          />
        </Column>
        {renderQuoteColumns([1, 2])}
        {renderQuoteColumns([3])}
        {renderQuoteColumns([4, 5])}
      </Columns>
    </Section>
  )
}
